import styled from "@emotion/styled";
import { Event } from "../../utils/api";
import { H2 } from ".";
import EventListing from "./EventListing";
import { DateTime } from "luxon";
import { useMemo } from "react";
import DateHeader from "./DateHeader";

const Root = styled.div({
  maxWidth: "1200px",
  margin: "auto",
});

interface Props {
  events: Event[];
  setEventPosition: (index: number) => (pos: number) => void;
  selectedIndex?: number;
}

function makeEventListingsWithDatesInserted(
  events: Event[],
): Array<Event | DateTime> {
  let currentDate = DateTime.fromISO(
    events[0].date ?? new Date().toISOString(),
  );
  // format to 'yyyy-MM-dd'
  let formattedDate = currentDate.toFormat("yyyy-MM-dd");
  const withEvents: Array<Event | DateTime> = [];
  events.forEach((event, index) => {
    if (index === 0) {
      withEvents.push(currentDate);
    }
    currentDate = DateTime.fromISO(event.date ?? new Date().toISOString());
    if (currentDate.toFormat("yyyy-MM-dd") !== formattedDate) {
      formattedDate = currentDate.toFormat("yyyy-MM-dd");
      withEvents.push(currentDate);
    }
    return withEvents.push(event);
  });
  return withEvents;
}

const LineupSchedule = ({
  events,
  setEventPosition: setEventPosition,
  selectedIndex,
}: Props) => {
  const withDates = useMemo(
    () => makeEventListingsWithDatesInserted(events),
    [],
  );

  let dateCount = 0;
  return (
    <Root>
      <H2>Schedule</H2>
      {withDates.map((eventOrDate, index) => {
        if (eventOrDate instanceof DateTime) {
          dateCount++;
          return (
            <DateHeader
              elevation={index > 0 ? 0 : 10}
              key={index}
              date={eventOrDate}
            />
          );
        } else {
          return (
            <EventListing
              key={index}
              event={eventOrDate as Event}
              venueName={eventOrDate.venue?.name ?? ""}
              setYPosition={setEventPosition(index - dateCount)}
              selected={selectedIndex === index}
            />
          );
        }
      })}
    </Root>
  );
};

export default LineupSchedule;
