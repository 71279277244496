import { useState } from "react";
import { ApiClient, Playlist, Track } from "../utils/api";
import { toast } from "react-toastify";
import { useUser } from "./useUser";
import { useSession } from "./useCookies";

export function useGetCurrentTrack(): [
  () => Promise<void>,
  { loading: boolean; error: string | null; response: Track | null },
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState<Track | null>(null);
  const [_, logout] = useSession();

  const getCurrentTrack = async () => {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await ApiClient.getCurrentTrack();
      setResponse(response);
    } catch (e: any) {
      if (e?.code === 421) {
        toast(
          `Woa, Spotify revoked our access, you'll need to re-login, sorry bout that!`,
          { type: "error" },
        );
        logout(() => window.location.reload());
        return;
      }
      setError(e?.message);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return [getCurrentTrack, { loading, error, response }];
}

export function useUpdatePlaylist(): [
  (playlistId?: string) => Promise<string>,
  { loading: boolean; error: string | null; response: any },
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  async function updatePlaylist(playlistId?: string) {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await ApiClient.updatePlaylist(playlistId);
      setResponse(response);
      return response.id;
    } catch (e: any) {
      setError(e?.message);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return [updatePlaylist, { loading, error, response }];
}

export function useSavePlaylist(): [
  (playlistId?: string) => void,
  { loading: boolean; error: string | null; response: any },
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [_, logout] = useSession();

  async function savePlaylist(playlistId?: string) {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await ApiClient.savePlaylist(playlistId);
      setResponse(response);
      toast(`playlist saved!`, { type: "success" });
    } catch (e: any) {
      if (e?.code === 421) {
        toast(
          `Woa, Spotify revoked our access, you'll need to re-login, sorry bout that!`,
          { type: "error" },
        );
        logout(() => window.location.reload());
        return;
      }
      setError(e?.message);
      toast(`${e?.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  }
  return [savePlaylist, { loading, error, response }];
}

export function useGetPlaylist(): [
  (playlistId?: string) => Promise<Playlist | null>,
  { loading: boolean; error: string | null; response: Playlist | null },
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState<Playlist | null>(null);

  async function getPlaylist(playlistId?: string) {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = (await ApiClient.getPlaylist(playlistId)) as Playlist;
      setResponse(response);
      return response;
    } catch (e: any) {
      setError(e?.message);
      toast(`${e?.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
    return null;
  }
  return [getPlaylist, { loading, error, response }];
}

export function useGetPlaylists(): [
  (userId: string) => Promise<Playlist[] | null>,
  { loading: boolean; error: string | null; response: Playlist[] | null },
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState<Playlist[] | null>(null);

  async function getPlaylist(userId: string) {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await ApiClient.getPlaylists(userId);
      setResponse(response);
      return response;
    } catch (e: any) {
      setError(e?.message);
      toast(`${e?.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
    return null;
  }
  return [getPlaylist, { loading, error, response }];
}
