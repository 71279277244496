import styled from "@emotion/styled";
import { useLocation } from "../hooks/useLocation";
import { ScaleLoader } from "react-spinners";
import { usePlatform } from "../hooks/platform";
import { Colors } from "../pages/constants";
import { useState } from "react";
import Button from "./generics/button";

const Root = styled.div({
  display: "flex",
  flexDirection: "column",
});

const GeoLink = styled.a({
  color: "white",
  textDecoration: "none",
});

const Input = styled.div({
  border: `2px solid ${Colors.turquoise}`,
  backgroundColor: "none",
  borderRadius: "8px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "8px",
  paddingBottom: "8px",
  margin: "auto",
  boxShadow: `7px 7px ${Colors.turquoise}`,
  display: "flex",
});

const ZipCode = styled.div({
  display: "flex",
});

const StyledInput = styled.input({
  width: "100%",
  alignSelf: "center",
  padding: "0 10px",
  border: "none",
  borderRadius: "8px",
  backgroundColor: Colors.background,
  color: "white",
  fontSize: "calc(10px + 2vmin)",
  appearance: "none",
  margin: "auto",
});

const SubmitZip = styled.div({
  cursor: "pointer",
  border: `1px ${Colors.turquoise}`,
});

const ZipCodeInput = ({
  onZipChange: onZipSelected,
}: {
  onZipChange: (zip: string) => void;
}) => {
  const [zip, setZip] = useState<string | undefined>();

  return (
    <ZipCode>
      <StyledInput
        placeholder="Enter your Zip Code"
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        onChange={(event) => {
          setZip(event.target.value);
        }}
        onKeyDown={(event) => {
          if (zip && event.key === "Enter") {
            onZipSelected(zip);
          }
        }}
      />
      {zip?.length === 5 && (
        <SubmitZip onClick={() => onZipSelected(zip)}>Go!</SubmitZip>
      )}
    </ZipCode>
  );
};

interface Props {
  onZipChange: (zip: string) => void;
  onLocationSelected: ({ lat, lng }: { lat: number; lng: number }) => void;
}

export const LocationInput = ({ onLocationSelected, onZipChange }: Props) => {
  const { isMobile } = usePlatform();
  const { getLocation, location } = useLocation((position, error?) => {
    if (error) {
      console.log(
        "could not get location :( use the field below. " + error?.message,
      );
    } else {
      onLocationSelected(position);
    }
  });

  const isLoading = location.loading;
  const getLocationText = location.error
    ? location.error
    : `${isMobile ? "Tap" : "Click"} to find venues near you`;

  if (isLoading) {
    return (
      <>
        <ScaleLoader color="white" loading={isLoading} />
        <p>Finding your location...</p>
      </>
    );
  }

  return (
    <Root>
      <Button.Brand onClick={getLocation}>
        <GeoLink>{getLocationText}</GeoLink>
      </Button.Brand>
      <p style={{ fontSize: "20px" }}>Or</p>
      <Input>
        <ZipCodeInput onZipChange={onZipChange} />
      </Input>
    </Root>
  );
};
