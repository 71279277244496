import styled from "@emotion/styled";
import { Venue } from "./Map";

const Root = styled.div({
  display: "flex",
  flexDirection: "column",
  border: "1px solid grey",
  margin: ".5rem",
  borderRadius: "5px",
  textAlign: "left",
  padding: "1rem",
});

const Header = styled.div({
  justifyContent: "space-between",
  display: "flex",
  width: "100%",
});

const NotFoundMessage = styled.div({
  fontSize: "20px",
});

export const VenueCard = ({
  index,
  venue,
  selected,
  onSelected,
}: {
  index: number;
  venue: Venue;
  selected: boolean;
  onSelected: () => void;
}) => {
  const notFound = !venue?.songkickId;
  return (
    <Root
      style={selected ? { backgroundColor: "grey" } : {}}
      onClick={() => (notFound ? () => {} : onSelected())}
    >
      <Header>
        <strong>
          {index + 1}: {venue.name}
        </strong>
        <input readOnly type="checkbox" checked={!!selected} />
      </Header>
      {notFound && (
        <NotFoundMessage>Sorry, no info found for this venue.</NotFoundMessage>
      )}
    </Root>
  );
};
