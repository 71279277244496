import Markdown from "react-markdown";
import styled from "@emotion/styled";
import { Colors } from "./constants";

if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}

const Root = styled.div({
  textAlign: "left",
  margin: "1rem",
  maxWidth: 800,
});

const StyledMarkdown = styled(Markdown)({
  textAlign: "left",
});

export const components = {
  h1(props: any) {
    return <h1 style={{ color: Colors.lightPurple }} {...props} />;
  },
  h2(props: any) {
    return <h2 style={{ color: Colors.lightPurple }} {...props} />;
  },
  h3(props: any) {
    return <h3 style={{ color: Colors.lightPurple }} {...props} />;
  },
  strong(props: any) {
    return <strong style={{ color: Colors.lightPurple }} {...props} />;
  },
  a(props: any) {
    return (
      <a
        style={{ color: Colors.lightPurple, textDecoration: "none" }}
        {...props}
      />
    );
  },
};

export const About = () => (
  <Root>
    <StyledMarkdown components={components}>{Text}</StyledMarkdown>
  </Root>
);

const Text = `
## What TF?
**Show Hungry** is a site dedicated to helping you see live music. The idea is simple, choose venues that are near you, and build a playlist based on who's coming to them in the upcoming month. Yea sure, the playlist isn't going to be all bangers and you might need to skip some songs you don't fancy, but hey when you dig something, you know they are going to be in town within the month, and you can go see them live! 

I think the best way to discover new music is seeing live shows. **Show Hungry** helps expose you to new music that you can go see live.

Are you Hungry? How Hungry? **Show Hungry**.

## Who TF?
Oh hey there, I'm [mike](https://www.michaelcueno.com). I was laid off after the startup I worked at [hit the fan](https://www.geekwire.com/2023/convoy-collapse-read-ceos-memo-detailing-sudden-shutdown-of-seattle-trucking-startup/). Now that I have some free time on my hands, I've been trying to go see more live music and to help I ended up making **Show Hungry** - instead of looking for a new job.. (If you are hiring, find me on [LinkedIn](https://www.linkedin.com/in/michael-cueno-75702147/))

## What's next?
I was thinking of adding more features like: 

* Automatic updates (weekly) to existing playlists.
* More than one playlist.
* Fine tune the duration and date window for playlists.
* Add more support for different types of venue calaeders (support more venues).

I'd probably have to start some kind of subscription to support this though, right now this thing costs me just enough each month that I don't mind, but if it gets any bigger I'll need some help paying for it. If you could ever see yourself paying for this please let me know [showhungryapp@gmail.com](mailto:showhungryapp@gmail.com).
`;
