import React from "react";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as FullStory from "@fullstory/browser";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { HomePage } from "./pages/HomePage";
import "./App.css";
import { Lineup } from "./pages/Lineup";
import Header from "./components/Header";
import { UserProvider } from "./context/user";
import { VenuePicker } from "./pages/VenuePicker";
import Profile from "./pages/Profile";
import styled from "@emotion/styled";
import { FontSize } from "./pages/constants";
import { Footer } from "./components/Footer";
import { Privacy } from "./pages/Privacy";
import { About } from "./pages/About";
import GoogleTagManager from "./components/GoogleTag";

FullStory.init({ orgId: "V5HEN" });

const Root = styled.div({
  textAlign: "center",
  backgroundColor: "#282c34",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: FontSize.Medium,
  color: "white",
});

function App() {
  return (
    <UserProvider>
      <Root>
        <GoogleTagManager />
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/new" element={<HomePage />} />
            <Route path="/venues" element={<VenuePicker />} />
            <Route path="/lineup" element={<Lineup />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <ToastContainer />
          <Footer />
        </Router>
      </Root>
    </UserProvider>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
