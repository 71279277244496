import styled from "@emotion/styled";

const Root = styled.div({
  display: "flex",
  alignItems: "center",
  padding: 20,
  border: "2px solid #ff5733",
  backgroundColor: "#fff",
  borderRadius: "5px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
});

const Title = styled.div({
  fontSize: 24,
  color: "#ff5733",
});

const Message = styled.div({
  fontSize: 18,
  color: "#ff5733",
});

interface Props {
  title: string;
  message: string;
}

const ErrorBox = ({ title, message }: Props) => {
  return (
    <Root>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Root>
  );
};

export default ErrorBox;
