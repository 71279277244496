import styled from "@emotion/styled";
import { BeatLoader } from "react-spinners";
import { Colors, FontSize } from "../../pages/constants";

const Base = styled.button({
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  padding: "1rem",
  margin: "auto",
  border: "none",
  fontSize: FontSize.Medium,
  cursor: "pointer",
});

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  loading?: boolean;
  className?: any;
  disabled?: boolean;
  loadingColor?: "white" | "black";
}

const BaseButton = ({
  onClick,
  children,
  loading,
  className,
  disabled,
  loadingColor,
}: Props) => {
  const _onClick = () => {
    if (loading) {
      console.log("skipping handler since still loading");
    } else {
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <Base
      disabled={disabled}
      className={className}
      onClick={onClick ? _onClick : undefined}
    >
      {loading ? (
        <BeatLoader color={loadingColor ? loadingColor : undefined} />
      ) : (
        children
      )}
    </Base>
  );
};

const Spotify = (props: Props) => (
  <SpotifyStyle {...props} loadingColor="white" />
);

const SpotifyStyle = styled(BaseButton)({
  backgroundColor: Colors.black,
  color: Colors.white,
  boxShadow: `3px 3px ${Colors.turquoise}`,
  "&:hover": {
    backgroundColor: `lighten(${Colors.darkPurple}, 50%)`,
  },
  "&:active": {
    boxShadow: `1px 1px ${Colors.turquoise}`,
  },
});

const Brand = styled(BaseButton)({
  border: `2px solid ${Colors.turquoise}`,
  backgroundColor: Colors.transparent,
  boxShadow: `7px 7px ${Colors.turquoise}`,
  "&:hover": {
    backgroundColor: `lighten(${Colors.darkPurple}, 50%)`,
  },
  "&:active": {
    boxShadow: `3px 3px ${Colors.turquoise}`,
  },
});

const Button = Object.assign(BaseButton, {
  Spotify,
  Brand,
});

export default Button;
