import styled from "@emotion/styled";
import { H2 } from "./lineup";
import { Colors, FontSize } from "../pages/constants";
import { useNavigate } from "react-router-dom";

const Root = styled.div({
  marginBottom: "2rem",
});

const Flex = styled.div({
  marginTop: "1rem",
  display: "flex",
  justifyContent: "space-between",
  fontSize: FontSize.Small,
  color: Colors.lightPurple,
});

const A = styled.a({
  cursor: "pointer",
});

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <Root>
      <H2>Support Live Music!</H2>
      Events provided by Songkick
      <Flex>
        <A onClick={() => navigate("/about")}>About</A>
        <A onClick={() => window.open("mailto:showhungryapp@gmail.com")}>
          Contact
        </A>
        <A onClick={() => navigate("/privacy")}>Privacy Policy</A>
      </Flex>
    </Root>
  );
};
