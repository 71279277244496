export class Logger {
  constructor(private prefix: string) {}

  public info(message: string) {
    console.info(this.formatMessage(message));
  }

  public warn(message: string) {
    console.warn(this.formatMessage(message));
  }

  public error(message: string, error?: Error) {
    console.error(this.formatMessage(message), error);
  }

  private formatMessage(message: string) {
    return `[${this.prefix}] ${message}`;
  }
}
