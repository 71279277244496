import React, { useCallback } from "react";

// import GoogleMapReact from 'google-map-react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow as InfoWindow,
} from "@react-google-maps/api";
import { Config } from "../config";
import { Colors } from "../pages/constants";
import { usePlatform } from "../hooks/platform";
import styled from "@emotion/styled";

export interface Venue {
  id: string;
  name?: string;
  latitude?: number;
  longitude?: number;
  songkickId?: string;
  venueInfo?: {
    venueUrl?: string;
  };
}

interface Props {
  center: {
    lat: number;
    lng: number;
  };
  venues: Venue[];
  selections: boolean[];
  onClickMarker: (index: number) => void;
}

// Without x close button
const InfoWindowContent = styled.div({
  button: {
    display: "none",
  },
  color: "black",
});

const containerStyle = (isMobile: boolean) =>
  isMobile
    ? {
        width: "100%",
        height: "40vh",
      }
    : {
        width: "100%",
        height: "87vh",
      };

export const Map = ({ center, venues, onClickMarker, selections }: Props) => {
  const platform = usePlatform();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: Config.googleApiKey,
  });
  const [isOpen, setIsOpen] = React.useState(venues.map(() => false));
  const onClickMarkerWrapper = useCallback(
    (index: number) => {
      setIsOpen((prev) => prev.map((_, i) => i === index));
      onClickMarker(index);
    },
    [setIsOpen, venues, selections],
  );
  const onLoad = React.useCallback((map: any) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    venues.forEach((venue) => {
      if (venue.latitude && venue.longitude) {
        bounds.extend(
          new window.google.maps.LatLng(venue.latitude, venue.longitude),
        );
      }
    });
    map.fitBounds(bounds);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle(platform.isMobile)}
      center={center}
      zoom={10}
      onLoad={onLoad}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        styles: MAP_STYLE,
      }}
    >
      <>
        {venues.map((venue, index) => (
          <Marker
            key={index}
            position={{ lat: venue.latitude ?? 0, lng: venue.longitude ?? 0 }}
            label={String(index + 1)}
            animation={window.google.maps.Animation.DROP}
            opacity={selections[index] ? 1 : 0.6}
            onClick={() => onClickMarkerWrapper(index)}
            onMouseOver={() =>
              setIsOpen((prev) => prev.map((_, i) => i === index))
            }
            onMouseOut={() => setIsOpen(venues.map(() => false))}
          >
            {isOpen[index] && (
              <InfoWindow>
                <InfoWindowContent>{venue.name}</InfoWindowContent>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

/**
 *
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMap
        onGoogleApiLoaded={({ map, maps }) => {
        debugger;
        console.log('got here');
          const bounds = new maps.LatLngBounds();
          venues.forEach((venue) => {
            bounds.extend(new maps.LatLng(
              venue.lat,
              venue.lng,
            ));
          });
          map.fitBounds(bounds);
          maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
              map.fitBounds(bounds);
            });
          });
        }}
        defaultZoom={20}
        defaultCenter={center}
        draggable={false}
        options={{
          fullscreenControl: false,
          disableDefaultUI: true,
        }}
      >
      {venues.map((venue, index) => (
        <VenueMarker
          key={index}
          lat={venue.lat}
          lng={venue.lng}
          text={venue.name ?? 'unknown name'}
        >
          {venue.name}
        </VenueMarker>
      )
      )}
      </GoogleMapReact>
    </div>
  );
const Marker = styled.div({
  color: 'black',
});

const VenueMarker = ({ children }: { text: string, lat?: number, lng?: number, children?: JSX.Element | string }) => (
  <Marker>
    {children}
  </Marker>
);

 */

const MAP_STYLE = [
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#e0dfe0",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#a8a9a8",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#5b5b5a",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: Colors.darkPurple,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
