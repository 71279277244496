import Markdown from "react-markdown";
import styled from "@emotion/styled";
import { H2 } from "../components/lineup";
import { components } from "./About";

if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}

const Root = styled.div({
  textAlign: "left",
  margin: "1rem",
  maxWidth: 800,
});

const P = styled.p({
  marginTop: 0,
  marginBottom: "3rem",
});

const Header = styled(H2)({
  marginLeft: 0,
  marginBottom: 0,
});

const StyledMarkdown = styled(Markdown)({
  textAlign: "left",
});

export const Privacy = () => (
  <Root>
    <Header>TLDR;</Header>
    <P>
      I won't share any or your data and I'll do my best to protect it. Nerds
      read on.
    </P>
    <StyledMarkdown components={components}>{PrivacyPocliyText}</StyledMarkdown>
  </Root>
);

const PrivacyPocliyText = `
## Privacy Policy for Show Hungry

Last Updated: **2023-10-01**

---

**Show Hungry**, ("I" "we," "our," or "us") is committed to protecting the privacy of its users. This Privacy Policy outlines how we collect, use, and protect your information when you use our web application ("App"). By using the App, you agree to the terms and practices described in this policy.

## 1. Information We Collect
### 1.1 Spotify Permissions
Our App requests the following permissions from your Spotify account:

View and Create Playlists: We request this permission to allow you to create, and view playlists within the App. We won't collect any information nor modify any other playlists that are not explicitly managed by the App.

View What You're Currently Listening To: We request this permission to provide you with the feature wherein the App will surface the event information for the Artist you are currently listening to (if said artist is within a playlist created by the App).

### 1.2 Usage Data
We may collect data about how you use our App, including your interactions with the App's features and content. This information helps us improve the App and enhance your experience.

## 2. How We Use Your Information
We use the information collected for the following purposes:

* To provide you with the services and features of the App, such as playlist management and displaying your current listening activity.

* To improve the App's functionality and user experience.

* To respond to your requests, questions, or comments.

* To ensure the security and integrity of the App and its services.

## 3. Data Sharing
   We do not share your personal information with third parties, and we have no intention to do so in the future. Your data is treated with the utmost confidentiality and is solely used to provide and enhance the services of our App.

## 4. Data Security
We employ industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

## 5. Changes to this Privacy Policy
We reserve the right to modify this Privacy Policy at any time. We will notify you of any changes by posting the updated policy within the App. Please review this Privacy Policy periodically to stay informed about how we are protecting your data.

## 6. Contact Us
If you have any questions or concerns about this Privacy Policy or our data handling practices, please contact us at showhungryapp@gmail.com

By using our App, you consent to the practices outlined in this Privacy Policy. Please make sure to review this policy regularly to stay informed about our data practices.
`;
