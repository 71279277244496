export const Colors = {
  black: "black",
  white: "white",
  background: "#282c34",
  spotifyGreen: "#1DB954",
  turquoise: "turquoise",
  darkPurple: "#1b0133",
  transparent: "transparent",
  lightPurple: "#bba5ff",
};

export const FontSize = {
  XLarge: "calc(30px + 2vmin)",
  Large: "calc(15px + 2vmin)",
  Medium: "calc(10px + 2vmin)",
  Small: "calc(5px + 2vmin)",
};

export const FontWeight = {
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};
