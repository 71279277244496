import styled from "@emotion/styled";
import { useEvents, useVenues } from "../hooks/useVenues";
import { Venues } from "../components/Venues";
import { ScaleLoader } from "react-spinners";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Venue } from "../components/Map";
import { TimeWindow } from "../components/TimeWindowPicker";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { alertFor } from "../utils/alert";
import { useOnce } from "../hooks/useOnce";

const Root = styled.div({});

export const VenuePicker = () => {
  const [getVenues, venues] = useVenues();
  const [params] = useSearchParams();
  const lat = Number(params.get("lat"));
  const lng = Number(params.get("lng"));
  const zip = params.get("zip");
  const navigate = useNavigate();
  const [getEvents, events] = useEvents();

  useOnce(() => {
    getVenues(lat, lng, zip);
  });

  const onVenuesSelected = async (
    selected: Venue[],
    timeWindow: TimeWindow,
  ) => {
    const venueIds = selected.map((v) => v.id);
    const data = await alertFor(getEvents(venueIds, timeWindow));
    if (!data?.events || data?.events?.length === 0) {
      toast("sorry, no events found, try selecting more venues", {
        theme: "dark",
      });
    } else {
      navigate(`/lineup?playlistId=${data?.playlistId}`, {
        state: { events: data?.events },
      });
    }
  };

  if (!zip && (!lat || !lng)) {
    navigate("/");
  }

  if (venues.error) {
    navigate("/");
    toast(venues.error, { theme: "dark", type: "error" });
    return null;
  }

  if (!venues || !venues.response || venues.loading) {
    return (
      <>
        <ScaleLoader color="white" loading={venues.loading} />
        <p>Finding venues near you...</p>
      </>
    );
  }

  return (
    <Root>
      <Venues
        venues={venues.response.venues}
        center={{ lat: venues.response.lat, lng: venues.response.lng }}
        onVenuesSelected={onVenuesSelected}
        loading={events.loading}
      />
    </Root>
  );
};
