import { TimeWindow } from "../components/TimeWindowPicker";
import { objToQueryString } from "./url";
import * as luxon from "luxon";
import { Logger } from "./Logger";

const LOG = new Logger("clients/Api");

export interface Track {
  id: string;
  name: string;
  artists: {
    id: string;
    name: string;
  }[];
}

export interface Playlist {
  id: string;
  name: string;
  createdAt: string;
  spotifyId: string;
  events?: Event[];
  start?: string;
  durationDays?: number;
  venueCount?: number;
  cityName?: string;
}

export interface Event {
  id: string;
  date?: string;
  artistImage?: string;
  artistPopularity?: number;
  genres?: string[];

  artistSpotifyId?: string;
  artistName?: string;
  ticketsUrl?: string;
  topSongName?: string;
  topSongId?: string;

  venue?: {
    name?: string;
  };
}

const devHeaders = {
  headers: new Headers({
    "ngrok-skip-browser-warning": "69420",
  }),
};

class APIError extends Error {
  public code: number;
  constructor(message: string, code: number) {
    super(message);
    this.code = code;
  }
}

export class ApiClient {
  static apiUrl = `https://${window.location.hostname}/api`;

  static async getCurrentTrack(): Promise<Track | null> {
    const response = await fetch(`${this.apiUrl}/getCurrentTrack`, {
      method: "GET",
      ...devHeaders,
    });
    if (response.status !== 200) {
      LOG.error(
        `Got bad response from api for getCurrentTrack: ${
          response.status
        } - ${JSON.stringify(response.body)}`,
      );
      throw new APIError(JSON.stringify(response.body), response.status);
    }
    return response.json();
  }

  static async updatePlaylist(playlistId?: string) {
    if (!playlistId) {
      throw new Error(`😢 Missing playlistId`);
    }
    const params = objToQueryString({
      playlistId,
    });
    const response = await fetch(`${this.apiUrl}/updatePlaylist?${params}`, {
      method: "POST",
      ...devHeaders,
    });
    if (response.status !== 200) {
      throw new Error(`😢 Failed to save, ${response.statusText}`);
    }
    return response.json();
  }

  static async savePlaylist(playlistId?: string) {
    if (!playlistId) {
      throw new Error(`😢 Missing playlistId`);
    }
    const params = objToQueryString({
      playlistId,
    });
    const response = await fetch(`${this.apiUrl}/savePlaylist?${params}`, {
      method: "POST",
      ...devHeaders,
    });
    if (response.status !== 200) {
      throw new Error(`😢 Failed to save, ${response.statusText}`);
    }
    return response.json();
  }

  static async getPlaylist(playlistId?: string) {
    const params = playlistId
      ? objToQueryString({
          playlistId,
        })
      : undefined;
    const response = await fetch(`${this.apiUrl}/getPlaylist?${params}`, {
      method: "GET",
      ...devHeaders,
    });
    if (response.status !== 200) {
      throw new Error(`😢 Failed to fetch lineup, ${response.statusText}`);
    }
    return response.json() as any as Playlist;
  }

  static async getPlaylists(userId: string) {
    const params = objToQueryString({
      userId,
    });
    const response = await fetch(`${this.apiUrl}/getPlaylists?${params}`, {
      method: "GET",
      ...devHeaders,
    });
    if (response.status !== 200) {
      throw new Error(`😢 Failed to fetch lineups, ${response.statusText}`);
    }
    return response.json() as any as Playlist[];
  }

  static async getVenues(
    latitude: number,
    longitude: number,
    zip: string | null,
  ) {
    const params = zip
      ? objToQueryString({
          zip,
          count: 10,
        })
      : objToQueryString({
          latitude,
          longitude,
          count: 10,
        });
    const response = await fetch(`${this.apiUrl}/nearby?${params}`, {
      ...devHeaders,
    });
    return response.json();
  }

  static async getEvents(
    venueIds: string[],
    timeWindow: TimeWindow,
  ): Promise<Event[]> {
    LOG.info(
      `fetching events for venues: ${JSON.stringify(
        venueIds,
      )}, timeWindow: ${JSON.stringify(timeWindow)}`,
    );

    const start = timeWindow.start ?? new Date();
    const end = luxon.DateTime.fromJSDate(start).plus(
      timeWindow.duration ?? { weeks: 2 },
    );
    const params = objToQueryString({
      venues: venueIds,
      start: timeWindow.start?.toISOString() ?? new Date().toISOString(),
      end: end.toISO()!,
    });
    const response = await fetch(`${this.apiUrl}/getEvents?${params}`, {
      ...devHeaders,
    });
    return response.json();
  }
}
