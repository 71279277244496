import { useCallback, useState } from "react";

export function useLocation(
  onComplete?: (position: { lat: number; lng: number }, error?: Error) => void,
) {
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const getLocation = useCallback(() => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          console.log("Latitude: " + latitude + ", Longitude: " + longitude);
          setLoading(false);
          onComplete?.({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          setError(error.message);
          setLoading(false);
          onComplete?.({ lat: 0, lng: 0 }, error as unknown as Error);
          alert("Unable to get location");
        },
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setError("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  }, [setLatitude, setLongitude, latitude, longitude, setError]);

  return {
    getLocation,
    location: {
      loading,
      error,
      latitude,
      longitude,
    },
  };
}
