import { useState } from "react";
import { TimeWindow } from "../components/TimeWindowPicker";
import { ApiClient } from "../utils/api";

export function useVenues(): [
  (latitude: number, longitude: number, zip: string | null) => void,
  { loading: boolean; error: string | null; response: any },
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  async function getVenues(
    latitude: number,
    longitude: number,
    zip: string | null,
  ) {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await ApiClient.getVenues(latitude, longitude, zip);
      if (
        (response.code && response?.code?.toString().startsWith(4)) ||
        response?.code?.toString().startsWith(5)
      ) {
        setError(
          response?.userReadableMessage ??
            "Sorry, something went wrong, try again",
        );
      } else {
        setResponse(response);
      }
    } catch (e: any) {
      setError(e?.message);
    } finally {
      setLoading(false);
    }
  }
  return [getVenues, { loading, error, response }];
}

export function useEvents(): [
  (
    venueIds: string[],
    timeWindow: TimeWindow,
  ) => Promise<{ playlistId: string; events: any[] } | undefined>,
  { loading: boolean; error: string | null; response: any },
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState<Event[] | null>(null);

  async function getEvents(venues: string[], timeWindow: TimeWindow) {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      // TODO - hacking this any type..
      const response = (await ApiClient.getEvents(venues, timeWindow)) as any;
      setResponse(response);
      return { playlistId: response.id, events: response.events };
    } catch (e: any) {
      setError(e?.message);
      return undefined;
    } finally {
      setLoading(false);
    }
  }
  return [getEvents, { loading, error, response }];
}
