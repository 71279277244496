import styled from "@emotion/styled";
import { FontSize } from "../../pages/constants";

const Root = styled.div({
  textAlign: "left",
});

const Title = styled.p({
  fontSize: FontSize.Medium,
  fontWeight: "bold",
  marginBottom: 0,
  marginTop: 0,
});

interface Props {
  title: string;
  children: any;
  className?: any;
  titleStyle?: any;
}

export const Label = ({ title, titleStyle, className, children }: Props) => {
  return (
    <Root className={className}>
      <Title style={titleStyle}>{title}</Title>
      {children}
    </Root>
  );
};
