import { useState } from "react";
import * as FullStory from "@fullstory/browser";

interface Session {
  userId: string;
  userName?: string;
}

export function useSession(): [Session | null, (callback: () => void) => void] {
  const [session, setSession] = useState<Session | null>(parseCookie());
  if (session?.userId) {
    console.log("calling identity with fullstory");
    FullStory.identify(session.userId);
  }

  const logout = (callback: () => void) => {
    document.cookie =
      "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setSession(null);
    if (callback) {
      callback();
    }
  };

  return [session, logout];
}

function parseCookie() {
  const sessionCookie = document.cookie
    .split(";")
    .find((c) => c.split("=")[0].trim() === "session");
  if (!sessionCookie) {
    console.log("setting user context to null");
    return null;
  } else {
    const rawSession = sessionCookie
      .split("=")
      .slice(1, sessionCookie.split("=").length)
      .join("=");
    const parsed = JSON.parse(atob(rawSession)) as Session;
    return parsed;
  }
}
