import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { Playlist as PlaylistType } from "../../utils/api";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../generics/Card";
import { Colors } from "../../pages/constants";
import { MobileViewPortSize, usePlatform } from "../../hooks/platform";
import { Label } from "../generics/Label";
import { Row as BaseRow } from "../generics/Row";

const Row = styled(BaseRow)({
  width: "100%",
});

const Root = styled(Card)({
  color: Colors.lightPurple,
  backgroundColor: Colors.black,
  display: "flex",
  flexDirection: "column",
  marginBottom: "1rem",
  "&:active": {
    boxShadow: "none",
  },
});

const Strong = styled.strong({
  color: "white",
});

const MiniText = styled.p({
  marginBottom: 0,
});

interface Props {
  playlist: PlaylistType;
}

const WhiteLabel = ({ title, children }: { title: string; children: any }) => (
  <Label titleStyle={{ color: "white" }} title={title}>
    {children}
  </Label>
);

const Playlist = ({ playlist }: Props) => {
  const navigate = useNavigate();
  const { isMobile } = usePlatform();
  const goToPlaylist = useCallback(() => {
    navigate(`/lineup?playlistId=${playlist.id}`);
  }, [navigate]);
  const createdAt = DateTime.fromISO(playlist.createdAt).toFormat("LLL dd");

  const starting = playlist.start
    ? DateTime.fromISO(playlist.start).toFormat("LLL dd")
    : undefined;
  const until =
    playlist.start && playlist.durationDays
      ? DateTime.fromISO(playlist.start)
          .plus({ days: playlist.durationDays })
          .toFormat("LLL dd")
      : undefined;

  return isMobile ? (
    <Root style={{ minWidth: "80vw" }} onClick={goToPlaylist}>
      <Row>
        <WhiteLabel title={"Created"}>{createdAt}</WhiteLabel>
        <WhiteLabel title="Name">{playlist.name}</WhiteLabel>
      </Row>
      <Row>
        {until && starting && (
          <MiniText>
            <Strong>Shows from: </Strong>
            {starting} &nbsp; <Strong>Until: </Strong>
            {until}
          </MiniText>
        )}
      </Row>
    </Root>
  ) : (
    <Root style={{ minWidth: MobileViewPortSize - 120 }} onClick={goToPlaylist}>
      <Row>
        <WhiteLabel title={"Created"}>{createdAt}</WhiteLabel>
        {starting ? (
          <WhiteLabel title={"Events From"}>{starting}</WhiteLabel>
        ) : null}
        {until ? <WhiteLabel title={"Until"}>{until}</WhiteLabel> : null}
        <WhiteLabel title="Name">{playlist.name}</WhiteLabel>
      </Row>
    </Root>
  );
};

export default Playlist;
