export function objToQueryString(
  obj: Record<string, string | number | string[]>,
) {
  const keyValuePairs = [];
  for (const key in obj) {
    // if obj[key] is an array, flatten it with commas
    let value = obj[key];
    if (Array.isArray(value)) {
      value = value.join(",");
    }
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(value),
    );
  }
  return keyValuePairs.join("&");
}
