import { toast } from "react-toastify";

const interval = 6_000;

export function alertFor<T>(waitedPromise: Promise<T>): Promise<T> {
  let finished = false;

  async function sayWithWait(message: string) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (finished) {
          rej();
        } else {
          say(message);
          res(message);
        }
      }, interval);
    });
  }

  sayWithWait("This could take a bit...")
    .then(() =>
      sayWithWait("I'm fetching calendars, and then all the artists songs..."),
    )
    .then(() => sayWithWait("...and spotify rate limits their APIs..."))
    .then(() =>
      sayWithWait("...and I'm using just one of the the cheapest servers"),
    )
    .then(() => sayWithWait("Ok this is starting to get embarassing."))
    .then(() => sayWithWait("Please don't leave"))
    .then(() => sayWithWait("I guess I can't make you stay..."))
    .then(() => sayWithWait("Maybe you should just go"))
    .catch(); // Expected to reject actually

  waitedPromise
    .then(() => {
      finished = true;
      toast.dismiss();
    })
    .catch((error) => {
      toast(`${error?.message}`, { type: "error" });
    });
  return waitedPromise;
}

function say(message: string) {
  toast(message, { theme: "dark", type: "info" });
}
