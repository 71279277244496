export const Config = (() => {
  const config = {
    apiUrl: "https://showhungry.com",
    googleApiKey: "AIzaSyCZ7G7BzksdqH1v02ge97q24oN5XAZ2dw4", // fromEnvOrDie('GOOGLE_API_KEY'),
  };
  if (process.env.NODE_ENV === "development") {
    config.apiUrl = "https://160f-204-131-234-237.ngrok-free.app";
  }
  return config;
})();

function fromEnvOrDie(name: string) {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Missing environment variable ${name}`);
  }
  return value;
}
