import styled from "@emotion/styled";
import { LocationInput } from "../components/GeolocationInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../context/user";

const Root = styled.div({
  margin: "1rem",
});

const Hero = styled.div({
  marginBottom: "2rem",
});

export const HomePage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();
  useEffect(() => {
    // If logged in (and directly navigated to), then go to their current lineup
    if (user && location.key === "default" && location.pathname !== "/new") {
      navigate("/lineup");
    }
  });

  const onLocation = ({
    lat,
    lng,
    zip,
  }: {
    lat?: number;
    lng?: number;
    zip?: string;
  }) => {
    if (zip) {
      navigate(`/venues?zip=${zip}`);
    } else {
      navigate(`/venues?lat=${lat}&lng=${lng}`);
    }
  };

  return (
    <Root>
      <Hero>
        {
          "Show Hungry makes you a spotify playlist based on artists that are coming to a venue near you!"
        }
      </Hero>
      <LocationInput
        onZipChange={(zip) => onLocation({ zip })}
        onLocationSelected={onLocation}
      />
      <img width="80%" src={process.env.PUBLIC_URL + "/images/monster_1.png"} />
    </Root>
  );
};
