import styled from "@emotion/styled";
import { useContext, useEffect } from "react";
import { UserContext } from "../context/user";
import { useGetPlaylists } from "../hooks/apiHooks";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import ErrorBox from "../components/generics/error";
import Playlist from "../components/profile/playlist";
import Button from "../components/generics/button";
import { Colors } from "./constants";
import { useOnce } from "../hooks/useOnce";

const Centered = styled.div({
  margin: "auto",
  height: "80vh",
  marginTop: "3rem",
});

const Root = styled.div({
  display: "flex",
  flexDirection: "column",
});

const Header = styled.h2({
  display: "flex",
  margin: "1.5rem",
  color: Colors.lightPurple,
});

const LogoutButton = styled(Button.Spotify)({
  marginRight: "1rem",
});

const Profile = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { logout } = useContext(UserContext);
  const [getPlaylists, { error, loading, response }] = useGetPlaylists();
  useOnce(() => {
    if (!userContext.user?.id) {
      console.log(`NO USER ID! ${JSON.stringify(userContext)}`);
      navigate("/");
      return;
    }
    getPlaylists(userContext.user?.id);
  });

  if (error) {
    return <ErrorBox title="Something went wrong :(" message={error} />;
  }

  const onLogout = () => {
    logout(() => navigate("/"));
  };

  const rawPlaylists = response ? [...response] : [];

  const sortedPlaylists = rawPlaylists?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  console.debug(`sortedplaylists count: ${sortedPlaylists.length}`);

  const lastActivePlaylist = sortedPlaylists?.find((p) => !!p.spotifyId);
  console.debug(`last active playlist: ${lastActivePlaylist?.id}`);
  if (lastActivePlaylist) {
    const index = sortedPlaylists?.indexOf(lastActivePlaylist);
    if (index && sortedPlaylists) {
      delete sortedPlaylists[index];
    }
  }

  const loadingContent = (
    <Centered>
      <ScaleLoader color="white" loading={loading} />
    </Centered>
  );

  const bodyContent = (
    <>
      {lastActivePlaylist && (
        <>
          <Header>Active Lineup</Header>
          <Playlist playlist={lastActivePlaylist} />
        </>
      )}

      {sortedPlaylists && sortedPlaylists.length > 0 && (
        <>
          <Header>History</Header>
          {sortedPlaylists.map((playlist, index) => (
            <Playlist key={index} playlist={playlist} />
          ))}
        </>
      )}
    </>
  );

  return (
    <Root>
      <LogoutButton onClick={onLogout}>Logout</LogoutButton>
      {loading ? loadingContent : bodyContent}
    </Root>
  );
};

export default Profile;
