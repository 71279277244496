import { createContext } from "react";
import { useSession } from "../hooks/useCookies";

interface User {
  name?: string;
  id?: string;
}

interface UserContextProps {
  user?: User;
  logout: (callback: () => void) => void;
}

export const UserContext = createContext({
  logout: () => {},
} as UserContextProps);

export const UserProvider = ({ children }: any) => {
  const [session, logout] = useSession();
  const user = session?.userId
    ? {
        name: session?.userName,
        id: session?.userId,
      }
    : undefined;

  return (
    <UserContext.Provider value={{ user, logout }}>
      {children}
    </UserContext.Provider>
  );
};
