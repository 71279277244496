import styled from "@emotion/styled";
import { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import LineupHeadliners from "../components/lineup/Headliners";
import LineupSchedule from "../components/lineup/Schedule";
import { Event } from "../utils/api";
import { useLocation } from "react-router";
import {
  useGetCurrentTrack,
  useGetPlaylist,
  useSavePlaylist,
  useUpdatePlaylist,
} from "../hooks/apiHooks";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { Row } from "../components/generics/Row";
import Button from "../components/generics/button";
import { H2 } from "../components/lineup";
import { FontSize } from "./constants";
import { UserContext } from "../context/user";
import { alertFor } from "../utils/alert";

const Root = styled.div({});

const Gap = styled.div({
  height: "2rem",
});

const SyncButton = styled(Button.Spotify)({
  margin: "1rem",
});

const EventCount = styled.div({
  alignSelf: "center",
  marginLeft: "1rem",
  marginBottom: "2rem",
});

const JumpToButton = styled(Button.Brand)({
  margin: "1rem",
  color: "white",
  fontSize: FontSize.Small,
  height: "2rem",
  textAlign: "center",
});

const LineupHeader = styled(H2)({
  marginBottom: 0,
});

const CURRENT_TRACK_DEDUPE_SECONDS = 15;

export const Lineup = () => {
  const location = useLocation();
  const [getPlaylist, { loading: getLoading, response: getResponse }] =
    useGetPlaylist();
  const navigate = useNavigate();
  const [savePlaylist, { loading: saveLoading, response: saveResponse }] =
    useSavePlaylist();
  const [updatePlaylist, { loading: updateLoading, response: updateResponse }] =
    useUpdatePlaylist();
  const [getCurrentTrack, { response: currentTrackResponse }] =
    useGetCurrentTrack();
  const [lastCheck, setLastCheck] = useState<Date | undefined>();

  const { user } = useContext(UserContext);

  // parse location.search params
  let playlistId: string | undefined;
  if (location.search) {
    const params = new URLSearchParams(location.search);
    playlistId = params.get("playlistId") as string;
  } else if (!user) {
    console.log("no user - navigating home");
    navigate("/");
  } else {
    console.log(
      "no playlist, but has user - find latest spotify synced playlist",
    );
    // Go to current active lineup
    // Achieved by getPlaylist() with no playlistId
  }

  const fetchCurrentlyListening = useCallback(async () => {
    if (user) {
      const lastCheckMilis = lastCheck
        ? new Date().getTime() - lastCheck.getTime()
        : null;
      if (
        !lastCheckMilis ||
        lastCheckMilis / 1000 > CURRENT_TRACK_DEDUPE_SECONDS
      ) {
        await getCurrentTrack();
        setLastCheck(new Date());
      } else {
        console.log(
          `Skipping current track request - last check ${lastCheckMilis}`,
        );
      }
    }
  }, [lastCheck]);

  // Do this once!
  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (!location.state?.events) {
      console.log("running hook to fetch playlist");
      getPlaylist(playlistId)
        .catch((reason) => {
          console.error(`error while fetching events: ${reason}`);
        })
        .then((playlist) => {
          if (!playlist?.id) {
            navigate(`/profile`);
          } else {
            navigate(`/lineup?playlistId=${playlist.id}`);
          }
        });
    }
    fetchCurrentlyListening();
  }, []);

  useEffect(() => {
    window.addEventListener("focus", fetchCurrentlyListening);
    return () => {
      window.removeEventListener("focus", fetchCurrentlyListening);
    };
  }, [fetchCurrentlyListening]);

  const events = (location?.state?.events as Event[]) ?? getResponse?.events;
  const spotifyId = getResponse?.spotifyId ?? saveResponse?.spotifyId;
  const venueCount = getResponse?.venueCount;
  const sortedEvents = events?.sort((a, b) => {
    if (a.date && b.date) {
      const alphaCompare = ("" + a.venue?.name).localeCompare(
        "" + b.venue?.name,
      );
      const dateCompare =
        new Date(a.date).getTime() - new Date(b.date).getTime();
      return dateCompare === 0 ? alphaCompare : dateCompare;
    }
    return 0;
  });
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [positions, setPositions] = useState<Array<Number>>(
    Array.from(Array(sortedEvents?.length)),
  );

  const setEventPosition = (index: number) => {
    return (position: number) => {
      console.log(`setting position for ${index} to ${position}`);
      positions[index] = position;
      setPositions(positions);
    };
  };

  const scrollToEvent = (event?: Event) => {
    const index = event
      ? sortedEvents?.findIndex(
          (e) => e.date === event.date && e.artistName === event.artistName,
        )
      : 0;
    const pos = positions[index ?? 0];
    console.log(
      `scrolling to event: ${event?.artistName} pos: ${pos} index: ${index}`,
    );
    setSelectedIndex(index);
    window.scrollTo({ top: Number(pos) - 80, behavior: "smooth" });
  };

  // If listening to an artist for an event, scroll to that event.
  useEffect(() => {
    const currentArtistIds = currentTrackResponse?.artists?.map((a) => a.id);
    if (currentArtistIds?.length) {
      const currentEvent = events?.find((e) => {
        return (
          e.artistSpotifyId && currentArtistIds.includes(e.artistSpotifyId)
        );
      });
      if (currentEvent) {
        scrollToEvent(currentEvent);
      }
    }
  }, [currentTrackResponse]);

  const jumpToSchedule = () => {
    // find the event closest to today.
    const closestEvent = events?.find(
      (e) =>
        new Date(e?.date ?? "2000-01-01")?.getTime() > new Date().getTime(),
    );
    scrollToEvent(closestEvent);
  };

  if (getLoading) {
    return (
      <>
        <ScaleLoader color="white" loading={getLoading} />
        <p>Fetching lineup...</p>
      </>
    );
  }
  if (!events || !sortedEvents) {
    return null;
  }
  if (!playlistId) {
    return null;
  }

  const clickUpdate = () => {
    const updatePromise = updatePlaylist(playlistId);
    alertFor(updatePromise);
  };

  const syncButton = (
    <SyncButton onClick={() => savePlaylist(playlistId)} loading={saveLoading}>
      {spotifyId ? "Sync Playlist with Spotify" : "Create Playlist in Spotify"}
    </SyncButton>
  );

  const updateButton =
    playlistId === updateResponse?.id ? null : updateResponse?.id ? (
      <SyncButton
        onClick={() => {
          navigate(`/lineup?playlistId=${updateResponse?.id}`, {
            state: { events: updateResponse?.events },
          });
        }}
        loading={updateLoading}
      >
        Go to updated playlist
      </SyncButton>
    ) : (
      <SyncButton onClick={clickUpdate} loading={updateLoading}>
        Update playlist
      </SyncButton>
    );

  // Consider breaking down lineup by "Week of ..."
  return (
    <Root>
      {user ? (
        <Row>
          {syncButton}
          {updateButton}
        </Row>
      ) : (
        <Row>Login to spotify to create a playlist for this lineup!</Row>
      )}
      <Row>
        <div>
          <LineupHeader>Lineup</LineupHeader>
          <EventCount>
            {events?.length && events.length} Events | {venueCount} Venues
          </EventCount>
        </div>
        <JumpToButton onClick={jumpToSchedule}>Jump to Next Show</JumpToButton>
      </Row>
      <LineupHeadliners
        onEventSelected={scrollToEvent}
        events={sortedEvents}
        playlistId={playlistId}
        spotifyId={spotifyId}
      />
      <Gap />
      <LineupSchedule
        events={sortedEvents}
        setEventPosition={setEventPosition}
        selectedIndex={selectedIndex}
      />
    </Root>
  );
};
