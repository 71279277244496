import { Helmet } from "react-helmet";

const GoogleTagManager = () => (
  <Helmet>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-4BJJG33N7L"
    ></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-4BJJG33N7L');
      `}
    </script>
  </Helmet>
);

export default GoogleTagManager;
