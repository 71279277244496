import styled from "@emotion/styled";
import { Colors } from "../pages/constants";
import SpotifyLogin from "./SpotifyLogin";
import { useLocation, useNavigate } from "react-router-dom";
import { usePlatform } from "../hooks/platform";
import { useCallback } from "react";

const Root = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: Colors.background,
  width: "100%",
  marginBottom: "2rem",
});

const Logo = styled.img({
  width: "100px",
  cursor: "pointer",
});

const Tagline = styled.h3({
  color: "white",
});

const Monster = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "1.5rem",
});

interface Props {}

const Header = ({}: Props) => {
  const navigate = useNavigate();
  const clickLogo = useCallback(() => navigate("/"), [navigate]);

  const { isMobile } = usePlatform();
  return (
    <Root>
      <Monster>
        <Logo
          onClick={clickLogo}
          width={"100px"}
          src={process.env.PUBLIC_URL + "/images/monster_1.png"}
        />
        {!isMobile && <Tagline>Me show hungry!</Tagline>}
      </Monster>
      <SpotifyLogin />
    </Root>
  );
};

export default Header;
