import styled from "@emotion/styled";
import { Event } from "../../utils/api";
import { DateTime } from "luxon";
import Button from "../generics/button";
import Card from "../generics/Card";
import { FontSize } from "../../pages/constants";
import { Grid } from "@mui/material";
import { useCallback } from "react";

const ArtistImage = styled.img({
  maxWidth: 120,
  height: "auto",
  boxShadow: "2px 2px 2px black",
  "&:active": {
    boxShadow: "none",
  },
});

const Root = styled(Card)({
  flexWrap: "wrap",
  gap: "2rem",
  alignItems: "center",
  margin: "1rem",
  boxShadow: "none",
});

// Root style with a background color animation fade in and out
const AnimatedRoot = styled(Root)({
  animation: "fadeIn 2s ease-in-out",
  "@keyframes fadeIn": {
    "0%": {
      backgroundColor: "rgba(0,0,0,0)",
    },
    "50%": {
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    "100%": {
      backgroundColor: "rgba(0,0,0,0",
    },
  },
});

const TicketsButton = styled(Button.Spotify)({
  boxShadow: "none",
});

const DateContainer2 = styled.div({});

const DateDay = styled.h1({
  marginBottom: 0,
  color: "lightgray",
});

const DateMonth = styled.h2({
  marginTop: 0,
  marginBottom: 0,
});

const BandName = styled.strong({
  textAlign: "left",
  marginBottom: 0,
  fontSize: FontSize.Large,
});

const VenueName = styled.p({
  marginTop: 0,
});

const FinePrint = styled.div({
  textAlign: "left",
  fontSize: "1rem",
  color: "lightgray",
});

const ArtistLink = styled.a({
  textAlign: "left",
  textDecoration: "none",
  color: "white",
  "&:hover": {
    textDecoration: "underline",
  },
});

interface Props {
  venueName: string;
  event: Event;
  setYPosition: (y: number) => void;
  selected?: boolean;
}

const spotifyPrefixUrl = "https://open.spotify.com/artist/";

const EventListing = ({
  event,
  venueName,
  setYPosition,
  selected: onSelected,
}: Props) => {
  const hasGenres = event.genres?.length ? event.genres.length > 0 : false;
  const RootA = onSelected ? AnimatedRoot : Root;
  const goToArtist = useCallback(() => {
    window.open(`${spotifyPrefixUrl}${event.artistSpotifyId}`, "_blank");
  }, [spotifyPrefixUrl, event.artistSpotifyId]);
  if (!event.date) {
    return null;
  }
  const date = DateTime.fromISO(event.date);
  return (
    <RootA
      ref={(el) => {
        if (!el) return;
        setYPosition(el.getBoundingClientRect().top + window.scrollY);
      }}
    >
      <Grid container alignItems="center" textAlign="left" spacing={2}>
        <Grid item xs={6} md={3} onClick={goToArtist}>
          {event.artistImage ? (
            <ArtistImage
              height="120"
              src={event.artistImage}
              alt={event.artistName}
            />
          ) : (
            <ArtistImage
              height="120"
              src={process.env.PUBLIC_URL + "/images/monster_1.png"}
              alt={event.artistName}
            />
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          <DateContainer2>
            <DateDay>{date.day}</DateDay>
            <DateMonth>{date.monthShort?.toUpperCase()}</DateMonth>
          </DateContainer2>
          <VenueName>{venueName}</VenueName>
        </Grid>
        <Grid item xs={6} md={3}>
          <ArtistLink
            href={`${spotifyPrefixUrl}${event.artistSpotifyId}`}
            target={"_blank"}
          >
            <BandName>{event.artistName}</BandName>
          </ArtistLink>
          {hasGenres && (
            <FinePrint>Genres: {event.genres?.join(", ")}</FinePrint>
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          {event.ticketsUrl && (
            <TicketsButton
              onClick={() => {
                window.open(event.ticketsUrl, "_blank");
              }}
            >
              Get Tickets
            </TicketsButton>
          )}
        </Grid>
      </Grid>
    </RootA>
  );
};

export default EventListing;
