import { Paper } from "@mui/material";
import { Colors } from "../../pages/constants";
import { DateTime } from "luxon";
import styled from "@emotion/styled";

interface Props {
  date: DateTime;
  elevation: number;
}

const H3 = styled.h3({
  paddingTop: "1rem",
  paddingBottom: "1rem",
});

const DateHeader = ({ date, elevation }: Props) => {
  const stickyProps = { position: "sticky", top: 0 };
  return (
    <Paper
      elevation={elevation}
      sx={{
        ...stickyProps,
        backgroundColor: Colors.background,
        textAlign: "left",
        paddingLeft: "1rem",
        color: Colors.white,
        alignItems: "center",
      }}
    >
      <H3>
        {date.toFormat("cccc")}, {date.toFormat("DDD")}
      </H3>
    </Paper>
  );
};

export default DateHeader;
