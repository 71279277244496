import styled from "@emotion/styled";
import { Colors, FontSize } from "../../pages/constants";

const BaseCard = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  padding: "1.5rem",
  margin: "auto",
  fontSize: FontSize.Medium,
  cursor: props.onClick ? "pointer" : "unset",
  border: `2px solid ${Colors.turquoise}`,
  backgroundColor: Colors.transparent,
  boxShadow: `3px 3px ${Colors.turquoise}`,
}));

export default Object.assign(BaseCard, {});
