import styled from "@emotion/styled";
import { useContext } from "react";
import { Config } from "../config";
import { UserContext } from "../context/user";
import Button from "./generics/button";
import { useNavigate } from "react-router-dom";

const Text = styled.p({
  marginTop: 8,
  marginBottom: 8,
  marginRight: 8,
});

const Root = styled.div({
  marginRight: "1rem",
});

const serverUrl = Config.apiUrl;

const SpotifyLogin = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const onClick = () => {
    if (user) {
      navigate("/profile");
    } else {
      console.log("logging in");
      window.location.href = `${serverUrl}/api/spotify/auth`;
    }
  };
  return (
    <Root>
      <Button.Spotify onClick={onClick}>
        <img height={40} width={40} src={"./spotify.png"} />
        {user ? <Text>{user.name}</Text> : <Text>Login with Spotify</Text>}
      </Button.Spotify>
    </Root>
  );
};

export default SpotifyLogin;
