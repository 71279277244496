import styled from "@emotion/styled";
import { Event } from "../../utils/api";
import { notEmpty } from "../../utils/types";
import { Colors, FontSize, FontWeight } from "../../pages/constants";
import { Typography } from "@mui/material";

const Root = styled.div({});

const ArtistsContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  alignItems: "center",
});

const ArtistCard = styled.a({
  marginRight: "1rem",
  marginLeft: "1rem",
  textDecoration: "none",
  color: "white",
  // Put a dot after each element except the last element
  "&:after": {
    display: "inline-block",
    content: '"•"',
    color: "white",
    fontSize: "2rem",
    marginLeft: "1rem",
    textDecoration: "none",
  },
  "&:last-child:after": {
    content: '""',
    marginRight: "0",
    textDecoration: "none",
  },
  "&:hover": {
    textDecoration: "underline",
  },
});

interface Props {
  events: Event[];
  onEventSelected: (event: Event) => void;
  playlistId: string;
  spotifyId?: string;
}

// Popularity is a number between 0 - 100
// Normalize this against the highest and lowest popularity in the list
const fontSizeForPopularity = (popularities: number[]) => {
  const max = Math.max(...popularities);
  const min = Math.min(...popularities);
  const range = max - min;
  return (popularity: number) => {
    // Normalize popularity to a number between 0 and 1
    const normalized = (popularity - min) / range;
    // Only want 4 different font sizes
    if (normalized < 0.25) {
      return {
        fontSize: FontSize.Small,
        fontWeight: FontWeight.normal,
      };
    } else if (normalized < 0.5) {
      return {
        fontSize: FontSize.Medium,
        fontWeight: FontWeight.medium,
      };
    } else if (normalized < 0.75) {
      return {
        fontSize: FontSize.Large,
        fontWeight: FontWeight.semiBold,
      };
    } else {
      return {
        fontSize: FontSize.XLarge,
        fontWeight: FontWeight.bold,
      };
    }
  };
};

const LineupHeadliners = ({ events, onEventSelected }: Props) => {
  const uniqueArtists = Array.from(
    new Set(events.map((e) => e.artistSpotifyId)),
  );
  const uniqueEvents = uniqueArtists
    .map((id) => events.find((e) => e.artistSpotifyId === id))
    .filter(notEmpty);
  const popularities = uniqueEvents
    .map((e) => e.artistPopularity)
    .filter(notEmpty);
  // Unique by artist id
  const getFontSize = fontSizeForPopularity(popularities);
  const first = uniqueEvents.filter(
    (e) => getFontSize(e.artistPopularity ?? 0).fontSize === FontSize.XLarge,
  );
  const second = uniqueEvents.filter(
    (e) => getFontSize(e.artistPopularity ?? 0).fontSize === FontSize.Large,
  );
  const thrid = uniqueEvents.filter(
    (e) => getFontSize(e.artistPopularity ?? 0).fontSize === FontSize.Medium,
  );
  const fourth = uniqueEvents.filter(
    (e) => getFontSize(e.artistPopularity ?? 0).fontSize === FontSize.Small,
  );
  const renderArtistLinup = (sizedEvents: Event[]) => {
    return (
      <ArtistsContainer>
        {sizedEvents?.map((e, index) => (
          <ArtistCard
            key={index}
            onClick={() => onEventSelected(e)}
            target="_blank"
            style={getFontSize(e.artistPopularity ?? 50)}
          >
            {e.artistName}
          </ArtistCard>
        ))}
      </ArtistsContainer>
    );
  };

  return (
    <Root>
      {renderArtistLinup(first)}
      {renderArtistLinup(second)}
      {renderArtistLinup(thrid)}
      {popularities.length < 100 ? (
        renderArtistLinup(fourth)
      ) : (
        <Typography sx={{ color: Colors.lightPurple, mt: "2rem" }}>
          smaller artists not shown
        </Typography>
      )}
    </Root>
  );
};

export default LineupHeadliners;
