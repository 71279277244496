import styled from "@emotion/styled";
import { Duration } from "luxon";
import { useCallback, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { usePlatform } from "../hooks/platform";
import { Grid } from "@mui/material";
import { Map, Venue } from "./Map";
import { TimeWindow, TimeWindowPicker } from "./TimeWindowPicker";
import { VenueCard } from "./VenueCard";
import Button from "./generics/button";

const Header = styled.h3({
  textAlign: "left",
  marginLeft: "1rem",
});

const Root = styled(Grid)({
  width: "100vw",
});

const Instructions = styled.div({
  display: "flex",
  margin: "1rem",
  fontsize: "12px",
  justifyContent: "space-between",
  textAlign: "center",
  alignItems: "center",
});

const ToggleButton = styled(Button.Brand)({
  color: "white",
  padding: "0.2rem",
  marginRight: 0,
});

const SubmitButton = styled(Button.Brand)({
  width: "92vw",
  height: "3rem",
  margin: "4vw",
  justifyContent: "center",
  color: "white",
});

interface Props {
  venues: Venue[];
  center: {
    lat: number;
    lng: number;
  };
  onVenuesSelected: (venues: Venue[], timewindow: TimeWindow) => void;
  loading: boolean;
}

export const Venues = ({
  venues,
  center,
  onVenuesSelected,
  loading,
}: Props) => {
  const [selections, setSelections] = useState(
    venues.map((_, _index) => false),
  );

  const flipSelection = (index: number) => {
    const newSelections = [...selections];
    newSelections[index] = !newSelections[index];
    setSelections(newSelections);
  };

  const allSelected = !selections.some((s) => !s);
  const toggleAll = useCallback(() => {
    if (allSelected) {
      setSelections(selections.map((_) => false));
    } else {
      setSelections(selections.map((_) => true));
    }
  }, [setSelections, selections, allSelected]);

  const platform = usePlatform();
  const [timeWindow, setTimewindow] = useState<TimeWindow | null>({
    duration: Duration.fromObject({ months: 1 }),
    start: new Date(),
  });

  const selectionsMade = selections.some((selection) => selection);
  const submitDisabled = !selectionsMade || !timeWindow || loading;
  const onClickMarker = useCallback(
    (index: number) => {
      flipSelection(index);
    },
    [flipSelection],
  );

  return (
    <Root container textAlign="left">
      <Grid item xs={12} md={7}>
        <Map
          center={center}
          venues={venues ?? []}
          onClickMarker={onClickMarker}
          selections={selections}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Instructions>
          {platform.isMobile ? "Tap" : "Click"} venues below to add them to your
          local lineup.
          <ToggleButton onClick={toggleAll}>
            Select {allSelected ? "None" : "All"}
          </ToggleButton>
        </Instructions>
        {(venues ?? []).map((venue, index) => (
          <div key={index}>
            <VenueCard
              index={index}
              venue={venue}
              selected={selections[index]}
              onSelected={() => flipSelection(index)}
            />
          </div>
        ))}
        {/* TODO Customization !platform.isMobile && <TimeWindowPicker onTimeWindowSelected={setTimewindow} /> */}
      </Grid>
      <SubmitButton
        disabled={submitDisabled}
        onClick={() => {
          const selectedVenues = venues.filter((_, index) => selections[index]);
          if (!timeWindow) {
            alert("please select a time window");
            return;
          }
          onVenuesSelected(selectedVenues, timeWindow);
        }}
      >
        {loading ? (
          <ScaleLoader color="white" loading={true} />
        ) : (
          <strong>
            {submitDisabled ? "Select some venues above!" : "Build my lineup!"}
          </strong>
        )}
      </SubmitButton>
    </Root>
  );
};
